<template>
  <div>
    <CChartPie
      :datasets="datasets"
      :labels="['MS', 'SW', 'APP']"
    />

    <CElementCover :opacity="0.9" v-show="loading" />
  </div>
</template>

<script>
import { CChartPie } from '@coreui/vue-chartjs'
import colors from "./colors.js";

export default {
  name: "ChartRevenueByOrderChannel",
  components: { CChartPie },
  props: {
    year: { default: null, type: String  },
    month: { default: null, type: Number },
    week: { default: null, type: Object },
    restaurant: { default: null, type: Number },
    revenues: { default: null, type: Array },
  },
  data() {
    return {
      datasets: [],
      loading: false
    };
  },

  async mounted() {
    this.getDetailsByOrderChannel();
  },

  methods: {
    async getDetailsByOrderChannel() {
      this.loading = true;

      var url = new URL(this.$backend.REPORTS.REVENUE_DETAILS_ORDER_CHANNEL),
        params = {
          year: this.year,
          month: this.month,
          week: this.week?.start,
          "restaurant-id": this.restaurant,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          this.datasets.push({
            data: response.data.values.map((v) => v.total),
            backgroundColor: response.data.values.map((v) => colors[v.roMedium] + "80"), // 80 -> 50% alpha
            // backgroundColor: "#" + ((Math.random() * 0xffffff) << 0).toString(16) + "80", // 80 -> 50% alpha
          });
          this.$emit("update:revenues", response.data.values)
        })
        .finally(() => this.loading = false)
    },
  },
};
</script>
