<template>
  <div>
    <CChartLine :datasets="orders" labels="months" />
  </div>
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  name: "ChartOrder",
  components: { CChartLine },
  props: {
    orders: { default: null, type: Array },
  }
};
</script>
