<template>
  <div v-if="!loading || revenues.chartDatasets.length > 0">
    <CCard>
      <CCardBody>
        <div>
          <h4 id="traffic" class="card-title mb-0">Revenue By Year</h4>
          <div v-if="selectedRestaurant" class="small text-muted">For restaurant <strong>{{ selectedRestaurant.label }}</strong> in {{ selectedYears.slice().sort().join(', ') }}</div>
          <div v-else class="small text-muted">in {{ selectedYears.slice().sort().join(', ') }}</div>
        </div>

        <!-- Filters -->
        <CCard class="filters my-3" accent-color="warning">
          <CCardBody class="p-2">
            <div class="grid">
              <v-select
                class="v-select-filter"
                v-model="selectedYears"
                :options="years"
                :searchable="false"
                multiple
              />

              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="restaurant"
                :reduce="r => r.value"
                :options="allRestaurants"
              />
            </div>
          </CCardBody>
        </CCard>

        <div v-if="selectedYears.length > 0" class="d-flex flex-md-row flex-column flex-gap-3">
          <div class="w-100">
            <div>
              <h5 class="chart-title">Revenues</h5>
              <ChartRevenue
                :revenues="revenues.chartDatasets"
              />
            </div>

            <div>
              <CDataTable
                v-if="revenues.tblItems.length > 0"
                class="table-sm"
                striped
                hover
                :items="revenues.tblItems"
                :fields="revenues.tblFields"
                :sorter="{ external: false, resetable: true }"
              >
                <template #month="{item}">
                  <td>
                    <strong>{{ item.month }}</strong>
                  </td>
                </template>

                <td v-for="(r, i) in revenues.data" :slot="r.year" slot-scope="{item}">
                  <CLink v-if="item[r.year]" @click="() => getRevenueDetails(null, r.year, item.m, restaurant)" >
                    {{ parseFloat(item[r.year]) | toCurrency('remove_thousand_separator') }}
                  </CLink>
                  <span v-else class="text-muted">{{ parseFloat(item[r.year]) | toCurrency('remove_thousand_separator') }}</span>
                </td>
              </CDataTable>
              <mc-spinner :opacity="0.9" v-show="revenues.data.length == 0 && selectedYears.length > 0" />
            </div>
          </div>

          <div class="w-100">
            <div>
              <h5 class="chart-title">Order Count</h5>
              <ChartOrder
                :orders="orders.chartDatasets"
              />
            </div>

            <div>
              <CDataTable
                v-if="orders.tblItems.length > 0"
                class="table-sm"
                striped
                hover
                :items="orders.tblItems"
                :fields="orders.tblFields"
                :sorter="{ external: false, resetable: true }"
              >
                <template #month="{item}">
                  <td>
                    <strong>{{ item.month }}</strong>
                  </td>
                </template>

                <td v-for="(r, i) in orders.data" :slot="r.year" slot-scope="{item}" class="text-center">
                  <CLink v-if="item[r.year]" @click="() => getRevenueDetails(null, r.year, item.m, restaurant)" >
                    {{ item[r.year] }}
                  </CLink>
                  <span v-else class="text-muted">{{ item[r.year] }}</span>
                </td>
              </CDataTable>
              <mc-spinner :opacity="0.9" v-show="orders.data.length == 0 && selectedYears.length > 0" />
            </div>
          </div>
        </div>

        <div v-else>
          <CAlert show color="warning" class="mt-2">
            <CIcon :height="24" name="cil-warning" class="mr-2" />
            <span>Please select a year!</span>
          </CAlert>
        </div>
      </CCardBody>
    </CCard>

    <!-- Revenue details -->
    <CCardGroup columns class="card-columns cols-3">
      <!-- by order channel -->
      <CCard v-for="item in reports.revenues.orderChannels">

        <CCardHeader>
          <strong v-if="item.month">{{ moment(item.month, 'M').format('MMMM') }} </strong>
          <strong>{{ item.year }}</strong>
          <small class="ml-2">{{ item.restaurant_name }}</small>

          <div class="card-header-actions">
            <CLink href="#" class="card-header-action btn-close" v-on:click="rejectRevenueDetails(item, 'orderChannels')">
              <CIcon name="cil-x"/>
            </CLink>
          </div>
        </CCardHeader>

        <CCardBody>
          <div class="d-flex align-items-center justify-content-center" style="height: 2rem;">
            <div class="w-100">
              <strong>{{ parseFloat(item.revenues?.reduce((acc, i) => acc + parseFloat(i.total), 0)) | toCurrency('remove_thousand_separator') }}</strong>
              Orders: <strong>{{ item.revenues?.reduce((acc, i) => acc + parseFloat(i.cnt), 0) }}</strong>
            </div>

            <div class="w-100">
              <v-select
                v-if="item.weeks && !item.week"
                placeholder="Select a week.."
                v-model="item.selectedWeek"
                :options="item.weeks"
                :searchable="false"
                @input="() => {
                  getRevenueDetails('orderChannels', item.year, item.month, item.restaurant, item.selectedWeek)
                  item.selectedWeek = null
                }"
              />
              <strong v-if="item.week">
                Week:
                {{ moment(item.week.start).format('DD/MM ddd') }} -
                {{ moment(item.week.end).format('DD/MM ddd YYYY') }}
              </strong>
            </div>
          </div>

          <ChartRevenueByOrderChannel
            :year="item.year"
            :month="item.month"
            :week="item.week"
            :restaurant="item.restaurant"
            :revenues.sync="item.revenues"
          />

          <div class="d-flex flex-column align-items-center">
            <h5 class="chart-title mt-2 mb-0">Order Channels</h5>
          </div>
        </CCardBody>

        <CFooter :fixed="false" class="justify-content-between footer-chart thin-scrollbar">
          <div v-for="r in item.revenues">
            <strong>{{ r.channel }}: </strong>
            <span>{{ parseFloat(r.total) | toCurrency('remove_thousand_separator') }}</span>
            <span class="font-weight-light font-italic"> ({{ r.cnt }})</span>
          </div>
        </CFooter>
      </CCard>

      <!-- by payment method -->
      <CCard v-for="item in reports.revenues.paymentMethods">
        <CCardHeader>
          <strong v-if="item.month">{{ moment(item.month, 'M').format('MMMM') }} </strong>
          <strong>{{ item.year }}</strong>
          <small class="ml-2">{{ item.restaurant_name }}</small>

          <div class="card-header-actions">
            <CLink href="#" class="card-header-action btn-close" v-on:click="rejectRevenueDetails(item, 'paymentMethods')">
              <CIcon name="cil-x"/>
            </CLink>
          </div>
        </CCardHeader>

        <CCardBody>
          <div class="d-flex align-items-center justify-content-center" style="height: 2rem;">
            <div class="w-100">
              <strong>{{ parseFloat(item.revenues?.reduce((acc, i) => acc + parseFloat(i.total), 0)) | toCurrency('remove_thousand_separator') }}</strong>
              Orders: <strong>{{ item.revenues?.reduce((acc, i) => acc + parseFloat(i.cnt), 0) }}</strong>
            </div>

            <div class="w-100">
              <v-select
                v-if="item.weeks && !item.week"
                placeholder="Select a week.."
                v-model="item.selectedWeek"
                :options="item.weeks"
                :searchable="false"
                @input="() => {
                  getRevenueDetails('paymentMethods', item.year, item.month, item.restaurant, item.selectedWeek)
                  item.selectedWeek = null
                }"
              />
              <strong v-if="item.week">
                Week:
                {{ moment(item.week.start).format('DD/MM ddd') }} -
                {{ moment(item.week.end).format('DD/MM ddd YYYY') }}
              </strong>
            </div>
          </div>

          <ChartRevenueByPaymentMethod
            :year="item.year"
            :month="item.month"
            :week="item.week"
            :restaurant="item.restaurant"
            :revenues.sync="item.revenues"
          />

          <div class="d-flex flex-column align-items-center">
            <h5 class="chart-title mt-2 mb-0">Payment Methods</h5>
          </div>
        </CCardBody>

        <CFooter :fixed="false" class="footer-chart thin-scrollbar">
          <div class="col-4 px-0" v-for="r in item.revenues">
            <strong>{{ r.pm }}: </strong>
            <span>{{ parseFloat(r.total) | toCurrency('remove_thousand_separator') }}</span>
            <span class="font-weight-light font-italic"> ({{ r.cnt }})</span>
          </div>
        </CFooter>
      </CCard>

      <!-- by referer -->
      <CCard v-for="item in reports.revenues.referers">
        <CCardHeader>
          <strong v-if="item.month">{{ moment(item.month, 'M').format('MMMM') }} </strong>
          <strong>{{ item.year }}</strong>
          <small class="ml-2">{{ item.restaurant_name }}</small>

          <div class="card-header-actions">
            <CLink href="#" class="card-header-action btn-close" v-on:click="rejectRevenueDetails(item, 'referers')">
              <CIcon name="cil-x"/>
            </CLink>
          </div>
        </CCardHeader>

        <CCardBody>
          <div class="d-flex align-items-center justify-content-center" style="height: 2rem;">
            <div class="w-100">
              <strong>{{ parseFloat(item.revenues?.reduce((acc, i) => acc + parseFloat(i.total), 0)) | toCurrency('remove_thousand_separator') }}</strong>
              Orders: <strong>{{ item.revenues?.reduce((acc, i) => acc + parseFloat(i.cnt), 0) }}</strong>
            </div>

            <div class="w-100">
              <v-select
                v-if="item.weeks && !item.week"
                placeholder="Select a week.."
                v-model="item.selectedWeek"
                :options="item.weeks"
                :searchable="false"
                @input="() => {
                  getRevenueDetails('referers', item.year, item.month, item.restaurant, item.selectedWeek)
                  item.selectedWeek = null
                }"
              />
              <strong v-if="item.week">
                Week:
                {{ moment(item.week.start).format('DD/MM ddd') }} -
                {{ moment(item.week.end).format('DD/MM ddd YYYY') }}
              </strong>
            </div>
          </div>

          <ChartRevenueByReferer
            :year="item.year"
            :month="item.month"
            :week="item.week"
            :restaurant="item.restaurant"
            :revenues.sync="item.revenues"
          />

          <div class="d-flex flex-column align-items-center">
            <h5 class="chart-title mt-2 mb-0">Referers</h5>
          </div>
        </CCardBody>

        <CFooter :fixed="false" class="footer-chart thin-scrollbar">
          <div class="col-6 px-0" v-for="r in item.revenues">
            <strong>{{ r.referer }}: </strong>
            <span>{{ parseFloat(r.total) | toCurrency('remove_thousand_separator') }}</span>
            <span class="font-weight-light font-italic"> ({{ r.cnt }})</span>
          </div>
        </CFooter>
      </CCard>

    </CCardGroup>
  </div>
  <CSpinner v-else color="primary" />
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash';
import ChartRevenue from './charts/Revenue'
import ChartOrder from './charts/Order'
import ChartRevenueByOrderChannel from './charts/RevenueByOrderChannel'
import ChartRevenueByPaymentMethod from './charts/RevenueByPaymentMethod'
import ChartRevenueByReferer from './charts/RevenueByReferer'
import colors from "./charts/colors.js";

export default {
  name: 'Revenue',
  components: {
    ChartRevenue,
    ChartOrder,
    ChartRevenueByOrderChannel,
    ChartRevenueByPaymentMethod,
    ChartRevenueByReferer
  },

  data() {
    return {
      revenues: {
        data: [],
        chartDatasets: [],
        tblItems: [],
        tblFields: [],
      },
      orders: {
        data: [],
        chartDatasets: [],
        tblItems: [],
        tblFields: [],
      },

      // Filters
      allRestaurants: [],
      selectedRestaurant: null,
      years: [],
      selectedYears: [],

      loading: true,
    }
  },

  computed: {
    ...mapState(['reports']),

    reloadParams() {
      return [
        this.restaurant,
        this.selectedYears,
      ];
    },

    restaurant: {
      get() { return this.reports.revenues.restaurant },
      set(value) { this.reports.revenues.restaurant = value },
    },
    qRestaurant() { return parseInt(this.$route.query.r) || null; }
  },

  watch: {
    selectedYears(val) {
      if (!val.length) {
        this.selectedYears = this.initYear();
      }
    },
    restaurant(val) { this.selectedRestaurant = this.allRestaurants.find(r => r.value == val); }
  },

  created () {
    this.restaurant ||= this.qRestaurant;
    this.selectedYears = this.initYear();
  },

  async mounted() {
    this.$watch('reloadParams', this.allRevenue)

    await Promise.all(
      [this.allRevenue(), this.getAllRestaurants()]
    );
  },

  methods: {
    initYear() {
      const currentYear = (new Date()).getFullYear();
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      this.years = range(currentYear, 2017, -1);
      return [currentYear - 1, currentYear];
    },

    async allRevenue() {
      this.loading = true;
      this.revenues = { data: [], chartDatasets: [], tblItems: [], tblFields: [] };
      this.orders = { data: [], chartDatasets: [], tblItems: [], tblFields: [] };

      await Promise.all(
        this.selectedYears.map((year) => this.getRevenue(year, this.restaurant)),
      ).then(() => {
        this.setTblRevenuesData()
        this.setTblOrdersData()
        this.loading = false;
      });
    },

    async getRevenue(year, restaurant = null) {
      var url = new URL(this.$backend.REPORTS.REVENUE),
        params = {
          year: year,
          "restaurant-id": restaurant,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url)
        .then(({data}) => {
          this.revenues.chartDatasets.push({
            label: data.year,
            data: data.values.map((v) => v.total),
            backgroundColor: colors[(data.year - 2017) + 20] + "80", // 80 -> 50% alpha
            // backgroundColor: "#" + ((Math.random() * 0xffffff) << 0).toString(16) + "80", // 80 -> 50% alpha
          });

          this.orders.chartDatasets.push({
            label: data.year,
            data: data.values.map((v) => v.cnt || 0),
            backgroundColor: colors[(data.year - 2017) + 20] + "80", // 80 -> 50% alpha
          });
        })
    },


    async getAllRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;
          this.selectedRestaurant = this.allRestaurants.find(r => r.value == this.restaurant);
          this.loading = false;
        })
    },

    async setTblRevenuesData() {
      this.revenues.data = this.revenues.chartDatasets.map((item) => { return { year:item.label, data:item.data }})

      this.revenues.tblFields = [];
      this.revenues.tblFields = this.revenues.data.map((item) => {
        return { key: item.year, label: `${item.year} (€)` };
      });

      this.revenues.tblItems = [
        { month: "January" },
        { month: "February" },
        { month: "March" },
        { month: "April" },
        { month: "May" },
        { month: "June" },
        { month: "July" },
        { month: "August" },
        { month: "September" },
        { month: "October" },
        { month: "November" },
        { month: "December" },
        { month: "TOTAL" },
      ];
      this.revenues.data.forEach((item) => {
        var total = 0;
        item.data.forEach((data, index) => {
          // this.revenues.tblItems[index][item.year] = this.$options.filters.toCurrency(parseFloat(data), 'remove_thousand_separator');

          total += parseFloat(data);
          this.revenues.tblItems[index][item.year] = data;
          // this.revenues.tblItems[index][item.year] = new Intl.NumberFormat("de-DE").format(data);

          this.revenues.tblItems[index]["m"] = index + 1;

          if (index === item.data.length - 1) {
            this.revenues.tblItems[index + 1][item.year] = total;
            // this.revenues.tblItems[index + 1][item.year] = new Intl.NumberFormat("de-DE").format(this.totalRequest);
          }
        });
        //this.revenues.tblItems[this.revenues.tblItems.length][item.year] = this.totalRequest;
      });

      this.revenues.tblFields.sort((a, b) => a.key - b.key);
      this.revenues.tblFields.unshift({ key: "month", label: "" });
    },

    async setTblOrdersData() {
      this.orders.data = this.orders.chartDatasets.map((item) => { return { year:item.label, data:item.data }})

      this.orders.tblFields = [];
      this.orders.tblFields = this.orders.data.map((item) => {
        return { key: item.year, label: `${item.year}`, _style: "text-align: center;", _classes: "pl-4 pr-0" };
      });

      this.orders.tblItems = [
        { month: "January" },
        { month: "February" },
        { month: "March" },
        { month: "April" },
        { month: "May" },
        { month: "June" },
        { month: "July" },
        { month: "August" },
        { month: "September" },
        { month: "October" },
        { month: "November" },
        { month: "December" },
        { month: "TOTAL" },
      ];

      this.orders.data.forEach((item) => {
        var total = 0;
        item.data.forEach((data, index) => {
          total += parseFloat(data);
          this.orders.tblItems[index][item.year] = data;

          this.orders.tblItems[index]["m"] = index + 1;

          if (index === item.data.length - 1) {
            this.orders.tblItems[index + 1][item.year] = total;
          }
        });
      });

      this.orders.tblFields.sort((a, b) => a.key - b.key);
      this.orders.tblFields.unshift({ key: "month", label: "" });
    },

    getRevenueDetails(filter, year, month, restaurant, week = null) {

      if (filter === null) {
        this.getRevenueDetails('orderChannels', year, month, restaurant, week);
        this.getRevenueDetails('paymentMethods', year, month, restaurant, week);
        this.getRevenueDetails('referers', year, month, restaurant, week);
        return;
      }

      var hasDuplicate = this.reports.revenues[filter].some(function(item) {
        return (
          item.year == year &&
          item.month == month &&
          item.week?.start === week?.start &&
          (item.restaurant === restaurant)
        );
      });

      if (hasDuplicate) {
        this.$toast.warning(`A chart already exists that matches these values.`);
      } else {
        const objData = {
          year,
          month,
          week,
          restaurant,
          restaurant_name: this.allRestaurants.find(item => item.value == restaurant)?.label
        };

        this.reports.revenues[filter].push({
          ...objData,
          weeks: (month && !week) ? this.getWeeksOfMonth(year, month) : null
        });
      }
    },

    rejectRevenueDetails(item, filter) {
      this.reports.revenues[filter] = _.reject(
        this.reports.revenues[filter],
        function (el) {
          return (
            el.year == item.year &&
            el.month == item.month &&
            el.week?.start == item.week?.start &&
            (el.restaurant === item.restaurant)
          );
        }
      );
    },

    getWeeksOfMonth(year, month) {
      const weeks = [];
      const firstDayOfMonth = this.moment(`${year}-${month}-01`).startOf('week');
      const lastDayOfMonth = this.moment(`${year}-${month}-01`).endOf("month").endOf('week');

      let currentWeek = firstDayOfMonth.clone();
      while (currentWeek.isSameOrBefore(lastDayOfMonth)) {
        // Do not show Sundays from the previous month!
        if (currentWeek.format('M') == month) {
          weeks.push({
            label: currentWeek.clone().format('DD MMM YYYY, dddd'),
            start: currentWeek.clone().format('YYYY-MM-DD'),
            end: currentWeek.clone().endOf('week').format('YYYY-MM-DD')
          });
        }
        currentWeek.add(1, 'week');
      }

      return weeks;
    },
  }
}
</script>

<style>
/*
.year-group .form-check-label {
  text-align: center;
  width: 48px;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 4px;
}

.year-group input[type=checkbox]:checked + .form-check-label {
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;
}
*/

.filters .grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

h5.chart-title {
  display: inline;
  border-bottom: 5px solid #f9dd94;
}

.card-columns .footer-chart {
  height: 4.5rem;
}
@media (min-width: 576px) {
  .card-columns .footer-chart {
    overflow: auto;
  }
}
</style>
